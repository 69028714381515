.cookies {
    &__wrapper {
        position: fixed;
        bottom: 24px;
        right: 80px;
        z-index: 11;
        display: none;
        align-items: center;
        justify-content: space-between;
        margin-left: auto;
        padding: 16px 16px 16px 48px;
        width: 380px;
        background: #FFFFFF;

        &.show {
            display: flex;
        }
    }
    &-close {
        display: block;
        text-transform: uppercase;
        text-align: right;
        line-height: 1;
        font-weight: 400;
    }
    &__message {
        width: 100%;
        font-weight: 300;
        font-size: 18px;
        & a {
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
}