@font-face {
    font-family: 'PF DinText Regular';
    src: url('../fonts/PFDinTextPro-Regular.eot');
    src: url('../fonts/PFDinTextPro-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/PFDinTextPro-Regular.woff2') format('woff2'),
    url('../fonts/PFDinTextPro-Regular.woff') format('woff'),
    url('../fonts/PFDinTextPro-Regular.ttf') format('truetype'),
    url('../fonts/PFDinTextPro-Regular.svg#PFDinTextPro-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PF DinText Medium';
    src: url('../fonts/PFDinTextPro-Medium.eot');
    src: url('../fonts/PFDinTextPro-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/PFDinTextPro-Medium.woff2') format('woff2'),
    url('../fonts/PFDinTextPro-Medium.woff') format('woff'),
    url('../fonts/PFDinTextPro-Medium.ttf') format('truetype'),
    url('../fonts/PFDinTextPro-Medium.svg#PFDinTextPro-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'PF DinText Thin';
    src: url('../fonts/PFDinTextPro-Thin.eot');
    src: url('../fonts/PFDinTextPro-Thin.eot?#iefix') format('embedded-opentype'),
    url('../fonts/PFDinTextPro-Thin.woff2') format('woff2'),
    url('../fonts/PFDinTextPro-Thin.woff') format('woff'),
    url('../fonts/PFDinTextPro-Thin.ttf') format('truetype'),
    url('../fonts/PFDinTextPro-Thin.svg#PFDinTextPro-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PF DinText Light';
    src: url('../fonts/PFDinTextPro-Light.eot');
    src: url('../fonts/PFDinTextPro-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/PFDinTextPro-Light.woff2') format('woff2'),
    url('../fonts/PFDinTextPro-Light.woff') format('woff'),
    url('../fonts/PFDinTextPro-Light.ttf') format('truetype'),
    url('../fonts/PFDinTextPro-Light.svg#PFDinTextPro-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}



@font-face {
    font-family: 'PF DinText ExtraThin';
    src: url('../fonts/PFDinTextPro-ExtraThin.eot');
    src: url('../fonts/PFDinTextPro-ExtraThin.eot?#iefix') format('embedded-opentype'),
    url('../fonts/PFDinTextPro-ExtraThin.woff2') format('woff2'),
    url('../fonts/PFDinTextPro-ExtraThin.woff') format('woff'),
    url('../fonts/PFDinTextPro-ExtraThin.ttf') format('truetype'),
    url('../fonts/PFDinTextPro-ExtraThin.svg#PFDinTextPro-ExtraThin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}
