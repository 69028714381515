/***
*
* iconfont.scss генерируется динамечески со списком классов для иконочного шрифта
* _iconfont-template.styl - шаблон для генерации файла iconfont.styl
*
***/

/* Генерация рандомного числа, для обнуления кеша при деплое на песок и прод */

//$rand: math.random(10000);

//@font-face {
//	font-family: "iconfont";
//	src: url('../fonts/iconfont/iconfont.eot?v='$rand);
//	src: url('../fonts/iconfont/iconfont.eot?v='$rand'#iefix') format('eot'),
//		url('../fonts/iconfont/iconfont.woff2?v='$rand) format('woff2'),
//		url('../fonts/iconfont/iconfont.woff?v='$rand) format('woff'),
//		url('../fonts/iconfont/iconfont.ttf?v='$rand) format('truetype'),
//		url('../fonts/iconfont/iconfont.svg?v='$rand'#iconfont') format('svg');
//}

@font-face {
	font-family: "iconfont";
	src: url('../fonts/iconfont/iconfont.eot');
	src: url('../fonts/iconfont/iconfont.eot?#iefix') format('eot'),
		url('../fonts/iconfont/iconfont.woff2') format('woff2'),
		url('../fonts/iconfont/iconfont.woff') format('woff'),
		url('../fonts/iconfont/iconfont.ttf') format('truetype'),
		url('../fonts/iconfont/iconfont.svg?#iconfont') format('svg');
}


[class^="icon-"], [class*=" icon-"] {
	display: flex;
	justify-content: center;
	align-items: center;
	&::before {
		font-family: "iconfont";
		speak: none;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		font-style: normal;
		font-variant: normal;
		font-weight: normal;
		text-decoration: none;
		text-transform: none;
	}
}


.icon-arrow-top::before {
	content: "\E001";
}
.icon-arrow::before {
	content: "\E002";
}
.icon-burger::before {
	content: "\E003";
}
.icon-close::before {
	content: "\E004";
}
.icon-dot::before {
	content: "\E005";
}
.icon-enter::before {
	content: "\E006";
}
.icon-pin::before {
	content: "\E007";
}
.icon-shift::before {
	content: "\E008";
}
.icon-facebook::before {
	content: "\E009";
}
.icon-instagram::before {
	content: "\E00A";
}
.icon-linkedin::before {
	content: "\E00B";
}
.icon-vimeo::before {
	content: "\E00C";
}