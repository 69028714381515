.location {
    position: fixed;
    left: 24px;
    top: 50%;
    transform: translateY(-50%) translateX(-50%) rotate(-90deg);
    z-index: 2;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: all .2s ease-in-out;
    & a {
        font-family: $font-regular;
        text-transform: uppercase;
    }
    & i {
        font-size: 24px;
    }
}
.page-nav {
    position: fixed;
    right: 24px;
    top: 50%;
    z-index: 2;
    transform: translateY(-50%);
    font-size: 14px;
    text-transform: uppercase;
    text-align: right;
    font-family: $font-regular;
}
.page-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 16px;
    font-family: $font-regular;
}
.project-menu {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-left: 0;
    margin-bottom: 0;
    & li {
        font-size: 14px;
        text-transform: uppercase;
        text-align: right;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        line-height: 1;
        height: 16px;
    }
}
.project-link {
    & a {
        display: flex;
        align-items: center;
        gap: 4px;
        & i {

            font-size: 6px;
            line-height: 16px;
        }
        & span {
            opacity: 0;
            padding-top: 4px;
        }
        &.active {
            & span {
                opacity: 1;
            }
            & i {
                display: none;
            }
        }
        &:hover {
            & span {
                opacity: 1;
            }
        }
    }

}

.back-to-top {
    display: flex;
    align-items: center;
    gap: 4px;
    opacity: 0;
    visibility: hidden;
    transition: all .2s ease;
    & span {
        font-family: $font-regular;
        text-transform: uppercase;
        text-decoration: underline;
    }
    &:hover {
        & span {

            text-decoration: none;
        }
    }
    &--mobile {
        margin-left: 16px;
    }
}
