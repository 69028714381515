.page {
    &-header {
        margin-bottom: 80px;
    }

    &-title {
        font-size: 56px;
        text-transform: uppercase;
        margin-bottom: 0;
    }

    &-update {
        font-family: $font-thin;
        font-size: 20px;
    }

    &-description {
        padding-left: 180px;
        margin-bottom: 60px;

        & p {
            font-size: 30px;
            font-family: $font-light;
            line-height: 1.2;
        }
    }
}

.section-page {
    padding: 100px 0;
    width: 100vw;
    height: 100vh;
    background-color: #000000;
}

.error-page {
    background-color: #000;
    height: 100vh;
    color: #fff;
}

.section-page-error {
    position: relative;
    display: flex;
    align-items: flex-end;
    height: 100%;
    padding: 180px 0;
}

.page-text {
    padding-top: 156px;
    padding-bottom: 60px;

    &-content {
        max-width: 1000px;
        margin: auto;
    }

    &-footer {
        position: relative;
        z-index: 11;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: flex-end;
        padding-bottom: 24px;

        & .copyright,
        .back-to-top {
            opacity: 1;
            visibility: visible;
        }

        & .back-to-top {
            justify-content: center;
        }
    }
}

.page-content {
    max-width: 640px;
    margin: 0 auto;
    color: #141D00 !important;
    font-family: "PF DinText Regular";
    font-weight: 300;
    & h4 {
        font-family: $font-light;
        font-size: 20px;
    }

    & p {
        font-size: 20px;
        font-family: $font-light;
        line-height: 1.2;
        margin-bottom: 24px;
    }

    & ul {
        margin-bottom: 60px;

        & li {
            font-size: 20px;
            font-family: $font-light;
            line-height: 1.2;
        }
    }

    & .page-description p {
        font-size: 32px;
        font-weight: 300;
        line-height: 38.4px;
        text-align: left;
    }

    & h5 {
        font-family: "PF DinText Regular", sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        text-transform: none;
        padding-top: 40px;
    }

    & b {
        font-family: "PF DinText Regular", sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
    }

    & a,
    & ol li {
        color: #0E008E;
        text-decoration: underline;
        font-family: "PF DinText Light", sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 32px;
        text-align: left;
    }

    & .chapter h5 {
        font-family: "PF DinText Regular", sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        margin-bottom: 24px;
        padding-top: 0;
    }

    & .chapter ul {
        margin-bottom: 20px;
    }

    & .chapter {
        padding-bottom: 40px;
    }

    & table td {
        font-family: "PF DinText Light", sans-serif; 
        font-size: 20px;
        font-weight: 300;
        line-height: 24px;
        text-align: left;

    }

    & table tr:nth-child(1) td {
        font-family: "PF DinText Regular", sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;

    }

    & table td {
        vertical-align: top;
        padding: 25px;

    }

    & table td:nth-child(3) {
        text-align: right;
    }

    & .addr {
        font-family: "PF DinText Light", sans-serif;
        font-size: 20px;
        font-weight: 300;
        line-height: 24px;
        text-align: left;

    }

    & p {
        /* font-family: "PF DinText Pro", sans-serif; */
        font-size: 20px;
        font-weight: 300;
        line-height: 24px;
        text-align: left;

    }
}

.error-message {
    margin-left: 200px;

    & h3 {
        display: flex;
        flex-direction: column;
        font-family: $font-regular;
        text-transform: uppercase;
        margin-bottom: 40px;

        & span {
            font-family: $font-thin;
        }
    }

    & a {
        text-transform: uppercase;
        text-decoration: underline;
        font-family: $font-thin;

        &:hover {
            text-decoration: none;
        }
    }
}

.static-page {
    & .cookies__wrapper {
        background-color: #FFFF92;
    }
}