.feedback {
    &-form {
        .error, .error input::placeholder, .error input, .error textarea, .error textarea::placeholder {
            color: #FF0000!important;
        }
        position: relative;
        padding: 48px;
        z-index: 1;
        display: grid;
        grid-template-columns: 310px 1fr;
        grid-gap: 60px;
        transform: translateX(150%);
        &:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: -100%;
            z-index: -1;
            background-color: rgba(219, 255, 0, 0.67);
            backdrop-filter: blur(15px);
        }
        &__header {
            display: flex;
            align-items: flex-start;
            gap: 24px;
            padding: 0 48px;
            & h3 {
                font-family: $font-regular;
                text-transform: uppercase;
            }
            & span {
                font-size: 20px;
                font-weight: 100;
            }
        }
        &-description {
            & h3 {
                font-family: $font-regular;
                font-size: 60px;
                text-transform: uppercase;
            }
            & p {
                font-family: $font-light;
                font-size: 32px;
                text-transform: uppercase;
            }
        }
        &-contacts {
            display: flex;
            flex-direction: column;
            gap: 8px;
            font-size: 24px;
            & p {
                font-weight: 100;
            }
        }
        &__left {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
        }
        &__right {
            border-left: solid 1px rgb(56 56 56 / 40%);
        }
        &__notice {
            & p {
                font-size: 12px;
                display: flex;
                align-items: center;
                gap: 8px;
                padding-left: 48px;
                margin-top: 12px;
            }
            & b {
                display: flex;
                align-items: center;
                gap: 2px;
                & i {
                    line-height: 12px;
                    font-size: 12px;
                }
            }
        }
        &__links {
            text-align: right;
            margin-top: 12px;
            & p {
                font-family: $font-light;
                font-size: 12px;
            }
            & a {

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
