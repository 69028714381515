
@media all and (max-width: 1800px) {
    .container {
        max-width: 968px;
    }
    .feedback-form {
        grid-template-columns: 310px 600px;
    }
}
@media all and (max-width: 1340px) {
    .container {
        max-width: 820px;
    }
    .project-params__digit {
        font-size: 76px;
    }
    .project-tags {
        font-size: 14px;
    }
    .feedback-form {
        grid-template-columns: 200px 600px;
    }
}


@media all and (max-width: 1200px) {
    .header-info, .header-nav, .header-contacts, .page-nav, .footer-links {
        display: none;
    }
    .container {
        padding: 0 16px;
    }
    .fp-viewing-anchor3, .fp-viewing-anchor4, .fp-viewing-anchor5, .fp-viewing-anchor6, .fp-viewing-anchor7, .fp-viewing-anchor8 {
        .logo-text {
            flex-direction: row;
            gap: 4px;
            line-height: 25px;
        }
    }

    .fp-viewing-anchor2, .fp-viewing-anchor3, .fp-viewing-anchor4, .fp-viewing-anchor5, .fp-viewing-anchor6, .fp-viewing-anchor7, .fp-viewing-anchor8 {
        & .location {
            display: none;
        }
    }
    .footer-row {
        & .copyright {
            display: none;
        }
    }
    .footer {
        & .socials {
            display: none;
        }
    }
    .about-title {
        margin-bottom: 40px;
        font-size: 26px;
    }
    .about-description {
        padding-left: 74px;
        & p {
            font-size: 24px;
        }
    }
    .about-description__rows {
        grid-gap: 20px;
    }
    .about-description__row {
        & p {
            font-size: 18px;
        }
    }
    .section-content {
        padding-top: 100px;
        &.section-content--00 {
            padding: 0;
        }
    }
    .scroll-down {
        width: 100%;
        bottom: 80px;
    }
    .project {
        gap: 60px;
    }
    .project-main-info {
        gap: 12px;
    }
    .project-title {
        font-size: 28px;
    }
    .project-tags, .project-params__list {
    }
    .project-description {
        padding-left: 74px;
        margin-bottom: 20px;
        & p {
            font-size: 20px;
        }

    }
    .project-feature {
        gap: 8px;
        & .project-title {
            font-size: 24px;
        }
    }
    .project-feature__description {
        margin-bottom: 8px;
        & p {
            font-size: 20px;
        }
        & ul li {
            font-size: 20px;
        }
    }
    .feedback-form {
        display: flex;
        flex-direction: column;
        padding: 0;
        gap: 0;
    }
    .feedback-form__notice p {
        display: none;
    }
    .feedback-form__left {
        padding: 24px;
        gap: 40px;
    }
    .feedback-form__right {
        padding: 24px;
        border-left: none;
        border-top: solid 1px rgba(56, 56, 56, 0.4);
    }
    .feedback-form__header {
        padding: 0;
        flex-direction: column;
        gap: 12px;
        & h3 {
            margin-bottom: 0;
        }
    }
    .form .form-input {
        padding-left: 0;
    }
    .footer {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
    }
    .back-to-top {
        margin-bottom: 0;
    }
    .project-params__list {
        overflow: auto;
    }
    .project-params {
        flex-shrink: 0;
    }
    .feedback-form-contacts {
        & .header-nav {
            display: flex;
        }
    }
    .mobile-nav__opener {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        font-size: 20px;
    }
}
@media all and (max-width: 680px) {
    .header {
        grid-template-columns: repeat(4, 1fr);
    }
    .logo-mobile {
        opacity: 1;
        visibility: visible;
        margin-left: 16px;
    }
    .mobile {
        & .logo, .location {
            opacity: 0;
            visibility: hidden;
        }
        &.feedback-section {
            & .logo.logo-feedback-section {
                opacity: 1;
                visibility: visible;
            }
        }
    }
    .scroll-down {
        position: fixed;
        bottom: calc((var(--vh, 1vh) * 20));
    }
    .section-content--02 {
        flex-direction: column;
        align-items: flex-start;
        gap: 40px;
        justify-content: flex-start;
        padding-top: 24px;
        height: 100%;
        max-height: unset;
        padding-bottom: 100px;
        & .logo-mobile {
            position: unset;
            margin-left: 16px;

        }
    }
    .project-img__wrapper {
        position: relative;
        height: 280px;
        & .project-feature-img__grid {
            position: absolute;
            left: -16px;
            right: -16px;
            padding: 0 16px;
            overflow: auto;
            grid-gap: 12px;
            &--row {
                grid-template-columns: repeat(4, 1fr);
            }
            &--four {
                & .project-feature-img {
                    width: 136px;
                }
            }
            &--three-line {
                & .project-feature-img {
                    width: 136px;
                }
            }

        }
        & .project-feature-img {
            width: 280px;
        }
    }
    .about-description__wrapper {
        position: relative;
        height: 90px;
        & .about-description__rows {
            position: absolute;
            left: -16px;
            right: -16px;
            display: grid;
            padding: 0 16px;
            overflow: auto;
            grid-gap: 12px;
        }
        & .about-description__row {
            width: 280px;
        }
    }
    .clients-list__wrapper {
        position: relative;
        height: 40px;
        & .clients-list {
            position: absolute;
            left: -16px;
            right: -16px;
            display: grid;
            grid-template-columns: repeat(12, 60px);
            justify-items: center;
            padding: 0 16px;
            overflow: auto;
            grid-gap: 12px;
        }
    }

    .section-content--feedback {
        background-position: -60px center;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        gap: 32px;
        padding-top: 24px;
        padding-bottom: 86px;
    }
    .section-page {
        padding: 60px 0;
    }
    .page-title {
        font-size: 26px;
    }
    .page-description {
        padding-left: 74px;
        & p {
            font-size: 20px;
        }
    }
    #logo {
        width: 130px;
        height: 56px;
    }
    .error-message {
        margin-left: 100px;
    }



    .img-parallax {
        &--pixelfy {
            background-size: 130%;
            background-position: top 43% center;
        }
        &--rebate-key {
            background-size: 85%;
            background-position: top 47% right;
        }
        &--picword {
            background-size: 90%;
            background-position: top 50% right;
        }
    }

    .form textarea {
        min-height: 112px;
    }
    .feedback-form-description {
        & h3 {
            font-size: 48px;
            line-height: 1;
        }
        & p {
            font-size: 28px;
            line-height: 1;
        }
    }
    .footer-row {
        & .back-to-top {
            display: none;
        }
    }
    .cookies__wrapper {
        width: auto;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 16px;
    }
    .static-page {
        & .location, .logo {
            display: none;
        }
    }
    .page-text {
        padding-top: 24px;
        display: flex;
        flex-direction: column;
        gap: 32px;
    }
    .page-text-footer {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        & .copyright {
            display: flex;
        }
    }

}



