.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    display: grid;
    grid-template-columns: 1fr 460px 460px 1fr;
    padding: 24px;
    &-download {
        opacity: 1;
        visibility: visible;
        transition: all 800ms ease;
    }
    &-info {
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        line-height: 1.2;
        opacity: 1;
        visibility: visible;
        transition: all 800ms ease;
        font-size: 16px;

        & .word {
            font-family: $font-regular;
        }
        & span {
            font-family: $font-thin;
        }
    }
    &-nav {
        display: flex;
        gap: 2px;
        text-transform: uppercase;
        & span {
            font-family: $font-thin;
        }
    }
    &-link {
      text-decoration: underline;
        font-family: $font-regular;
        &:hover {
            text-decoration: none;
        }
    }
    &-contacts {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 4px;
    }
}

