.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 54px;
    line-height: 40px;
    width: auto;
    font-size: 22px;
    box-sizing: border-box;
    text-align: center;
    user-select: none;
    padding: 0 20px;
    color: $color-text;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 500;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 0.2s;
    cursor: pointer;
    border-radius: 32px;
    i {
        font-size: 14px;
        margin-left: 10px;
        text-shadow: none;
    }
    &:hover {
        transition: all 0.2s;
    }
    &--submit {
        font-size: 24px;
        text-transform: uppercase;
        text-decoration: underline;
        color: #221603;
        &:hover {
            text-decoration: none;
        }
    }
    &--s1 {
        width: 260px;
        max-width: 100%;
    }
    &--s2 {
        width: auto;
        min-width: 280px;
        padding: 0 26px;

    }
    &--t1 {
        color: #FFFFFF;
        background: #F66F4D;
        border: 4px solid transparent;
        &:hover {
            border: 4px solid #FFD2C7;
        }
    }
    &--t2 {
        border: 1px solid #260303;
        &:hover {
            border: 4px solid #DDDDDD;
        }
    }

}
