body {
	color: $color-text;
	transition: color 0.4s;
	background-color: $color-body;
	z-index: 0;
	overflow-x: hidden;
	scrollbar-width: none; /* Firefox */
	-ms-overflow-style: none; /* IE и Edge */
}
.helper {

}
.main {
	position: relative;
	z-index: 1;

}

// -----------------------------------------------
// Container
// -----------------------------------------------
.container {
	position: relative;
	margin: 0 auto;
	padding: 0 24px;
	max-width: 1288px;
	width: 100%;
	&--fixed {
		position: fixed;
		z-index: 11;
		bottom: 24px;
		left: 0;
		right: 0;
		max-width: 1440px;
	}
}
::-webkit-scrollbar {
	display: none;
}


// -----------------------------------------------
// Animation
// -----------------------------------------------


#slogan-text {
	overflow: hidden;
	white-space: nowrap;
	border-right: 2px solid;
	animation: blink 0.75s step-end infinite;
	font-weight: 400;
	display: flex;
	height: 20px;
}

@keyframes blink {
	from { border-color: transparent; }
	to { border-color: black; }
}
