.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: flex-end;
    padding: 24px;
    &-links {

        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        & span {
            font-family: $font-regular;
        }
        & a {
            font-family: $font-thin;
        }
    }
    &-socials {
        & .socials {
            display: flex;
            flex-direction: column;
            gap: 8px;
            width: fit-content;
            margin-left: auto;
        }
        & .socials__link {
            display: flex;
            font-size: 24px;
        }
    }

}

.copyright {
    display: flex;
    flex-direction: column;
    gap: 10px;
    opacity: 0;
    visibility: hidden;
    transition: all 800ms ease;
    &-links {
        display: flex;
        gap: 24px;
    }
    &-link {
        text-transform: uppercase;
        text-decoration: underline;
        font-family: $font-regular;
        &:hover {
            text-decoration: none;
        }
    }
    &-text {
        font-family: $font-thin;
    }
}

