.about {
    display: flex;
    flex-direction: column;
    gap: 40px;
    &-title {
        position: relative;
        font-size: 48px;
        text-transform: uppercase;
        margin-bottom: 40px;
    }
    &-description {
        position: relative;
        padding-left: 180px;
        & p {
            font-size: 28px;
        }
        &__rows {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 48px;
        }
    }

}

.animated {
    opacity: 1 !important;
    transform: translateY(0) !important;
}

.leave {
    opacity: 0 !important;
    transform: translateY(-150px) !important;
}

.leave-back {
    opacity: 0 !important;
    transform: translateY(150px) !important;
}