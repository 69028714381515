.section-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 0;
    overflow-x: hidden;
    &-img {
        width: 100%;
        height: 100%;
    }
    &-title {
        & {
            text-transform: uppercase;
        }
    }
    &--00 {
        height: 100vh;
        overflow: hidden;
        padding: 0;
    }
    &--01 {
        height: 100vh;
        overflow: hidden;
        padding: 0;
    }
    &--02 {
        min-height: 100vh;
        max-height: 1600px;
        padding-top: 160px;
    }

    &--feedback {
        min-height: 100vh;
        overflow: hidden;
        background-image: url('../../img/backgrounds/main01.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}
.clients-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}





.slogan {
    position: relative;
    width: 100%;
    height: 100%;
    &-container {
        position: relative;
        width: 200px;
        height: 20px;
        overflow: hidden;
    }

}

.word {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    opacity: 0;
    transform: translateY(100%);
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.word.active {
    opacity: 1;
    transform: translateY(0);
}

.word.exit {
    opacity: 0;
    transform: translateY(-100%);
}

.section1{
    background-color: #FFFF92;

}

.section {
    position: relative;
    height: 100%;
    &--divider {
        position: relative;
        height: 100px;
    }
}