.scroll-down {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    margin-bottom: 24px;
    z-index: 1;
    //transition: opacity 0.3s, transform 0.3s;
    & span {
        font-family: $font-regular;
        text-transform: uppercase;
    }
    &__dot {
        position: relative;
        width: 1px;
        height: 50px;
        background-color: #221603;
        &:before {
            content: '';
            position: absolute;
            left: -10px;
            width: 21px;
            height: 21px;
            border-radius: 10px;
            background-color: #221603;
            animation: scrollDown 2.5s ease infinite;
        }
    }
}

@keyframes scrollDown{
    0%{
        transform: translateY(5px);
        opacity: 0;
    }
    50%{
        transform: translateY(0px);
        opacity: 1;
    }
    100%{
        transform: translateY(25px);
        opacity: 0;
    }
}