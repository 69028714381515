.logo {
    margin-right: auto;
    display: block;
    transition: opacity .2s ease-in-out;
    .logo-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2px;
        text-transform: uppercase;
        position: relative;
    }

    .text {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        transition: transform 0.8s ease-out;
        //transform: translateY(100%);
        font-family: $font-regular;
        line-height: 1;
    }
    .text.new-text {
        transform: translateY(100%);
    }
    .text.old-text {
        transform: translateY(-100%);
    }
    .line {
        display: flex;
        height: 16px;
        width: 100%;
        position: relative;
        overflow: hidden;
    }
}
.logo-mobile {
    //position: absolute;
    //top: 24px;
    //left: 16px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.8s ease-out;
    & .logo-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2px;
        text-transform: uppercase;
        position: relative;
    }
    & .logo-line {
        display: flex;
        height: 16px;
        width: 100%;
        position: relative;
        overflow: hidden;
    }
}