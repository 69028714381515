.dark {
    & .header, .footer, .location, .page-nav {
        color: #FFFFFF;
        opacity: .6;
    }
    & .header-info, .header-download {
        opacity: 0;
        visibility: hidden;
    }
}
.hide-elements {
    & .header-info, .header-download {
        opacity: 0;
        visibility: hidden;
    }
}
.feedback-section {
    & .back-to-top, & .copyright {
        opacity: 1;
        visibility: visible;
    }
}

.fullscreen-image {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-color: #FFFF92;

}
.background-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    &--first {
        z-index: 1;
    }
    &--two {
        z-index: 0;
    }
}
.background-img.current {
    opacity: 1;
}