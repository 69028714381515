html {
	font-size: $font-size-default;
	font-family: $font-regular;
}
h1,
h2,
h3,
h5 {
	line-height: normal;
	user-select: none;
	margin-bottom: 24px;
	font-family: $font-thin;
	font-weight: 100;
}
h1 {
	display: block;
	width: 100%;
	font-size: 56px;
}
h2 {
	display: block;
	width: 100%;
	font-size: 40px;
	line-height: 1.1;
}
h3 {
	font-size: 24px;
	line-height: 1.2;
	margin-bottom: 16px;
}
h4 {
	font-family: $font-light;
	font-size: 24px;
	margin-bottom: 24px;
}
h5 {
	font-size: 16px;
	text-transform: uppercase;
}
h6 {
	font-size: 16px;
	text-transform: uppercase;
	line-height: 1.5;
}
p {
	font-size: 24px;
	margin-bottom: 16px;
	line-height: 1.5;
	font-family: $font-thin;
	&:last-child {
		margin-bottom: 0;
	}
}
li,
a {
	user-select: none;
}
b {
	font-family: $font-medium;
}
